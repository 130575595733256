import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Glossary from "../components/Glossary"

function Page({ data, location }) {
  return (
    <Layout title="Glossar zu Apps">
      <SEO title="Glossar zu Apps" />
      <Glossary edges={data.allMdx.edges} location={location} />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/markdown-pages/glossar-zu-apps/.*.mdx$/" }
      }
      sort: { fields: frontmatter___term, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            term
            desc_short
            tags
            filtered_categories
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Page
